import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout/Layout"
import GlobalContext from "../../context/GlobalContext"
import { setDataLayer } from "../../helpers/dataLayerSetter"
import componentMaker from "../../helpers/ComponentMaker/componentMaker"
import ListingPageTemplate from "../../components/UI/ListingPageTemplate/ListingPageTemplate"
import { makePerex } from "../../helpers/DataConvertor.helper"
import { mainRecipeCategories } from "../../constants/mondelezAPI.contants"
import { gTagScriptConsent } from "../../components/Scripts/Gtm.Head"

const ListingPage = ({ path, pageContext }) => {
    const { apiData, globalProps, seoData, pageType, dataLayer, globalLabels, brandTheme } = pageContext

    const finalSeoData = seoData || {}
    finalSeoData.title = seoData?.metaTitle || " ",
    finalSeoData.metaDescription = makePerex(seoData?.metaDescription, 200) || "",
    finalSeoData.category = brandTheme?.brandName || "",

    setDataLayer(dataLayer, finalSeoData, pageType)

    const listingPageType = pageContext.pageProps?.contentfulListingPage?.type
    const collectionId = pageContext.pageProps?.contentfulListingPage?.collectionId
    let collectionIdsFromMainCategory = null

    if (mainRecipeCategories.indexOf(collectionId?.trim()) !== -1) {
        const { allRecipeCategory } = useStaticQuery(graphql`
            query {
                allRecipeCategory {
                    nodes {
                        id
                        name
                        subcategories {
                            CategoryId
                            CategoryName
                        }
                    }
                }
            }
        `)
        const mainCategory = allRecipeCategory?.nodes?.find(node => node.name === collectionId)

        collectionIdsFromMainCategory = mainCategory?.subcategories.map(subcat => subcat.CategoryId)
    }

    const finalCollectionIds = collectionIdsFromMainCategory || [collectionId]

    return (
        <GlobalContext labels={globalLabels} brandTheme={brandTheme}>
            <Layout path={path} seoData={seoData} globalProps={globalProps}>
                {componentMaker(pageContext.pageProps?.staticResult?.topContentComponents)}

                <ListingPageTemplate
                    type={listingPageType}
                    collectionIds={finalCollectionIds}
                    apiData={apiData}
                    mainCategory={collectionId}
                />

                {componentMaker(pageContext.pageProps?.staticResult?.bottomContentComponents)}
            </Layout>
        </GlobalContext>
    )
}

ListingPage.propTypes = {
    path: PropTypes.string,
    pageContext: PropTypes.shape({
        pageProps: PropTypes.object,
        apiData: PropTypes.array,
        recipeSubCategories: PropTypes.array,
        globalProps: PropTypes.object,
        seoData: PropTypes.object,
        pageType: PropTypes.string,
        dataLayer: PropTypes.object,
        globalLabels: PropTypes.object,
        brandTheme: PropTypes.shape({
            themeName: PropTypes.string,
            brandName: PropTypes.string,
        }),
    }),
}

/**
 * Adding to Layout ?
 */
export const Head = () => {
    const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}

    return (
        <>
            <script
                id="first-unique-id"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
                }}
            />
            <script
                async
                type="text/javascript"
                dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
            />
        </>
    )
}

export default ListingPage
